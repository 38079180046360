/* NOTE example of how .css works in this project (see README.md "styles") */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card--env {
  margin: 0.4vh;
  filter: brightness(0.8);
}
